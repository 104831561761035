<template>
  <el-button v-on:click="callbackPromise()" :icon="loading ? 'el-icon-loading' : 'el-icon-download'"
    :disabled="loading || noPermission" size="small" data-testid="download-button"
    class="download-btn">{{$t('ibReport.download') }}</el-button>
</template>

<script>
export default {
  props: ['text', 'callback', 'noPermission'],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    callbackPromise() {
      this.loading = true;
      var promise = this.callback.call();
      promise.then(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss">
.download-btn {
  border: 1px solid $blue;
  padding: 9px 15px;
  color: $blue;
  &:hover {
    background: transparent;
    border: 1px solid $blue;
    color: $blue;
  }
}

.el-button.is-disabled,.el-button.is-disabled:hover {
  background-color: #EBEEF5;
}

</style>
