<template>
  <div>
    <el-dialog
      :visible="downloadVisible"
      :title="$t('ibReport.download')"
      @close="close"
      :close-on-click-modal="false"
      class="update_wrapper"
      top="1vh"
    >
      <div class="ibReportDialog">
        <div class="dialog-content">
          <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate" :shortcutVisible="false"></DateRangePicker>
        </div>
        <div class="dialog-err">
          {{ errorText }}
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button  :disabled="buttonDisabled" class="btn-blue" style="width: 100%;" @click="handleConfrim">{{$t('ibReport.comfirm')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import DateRangePicker from '@/components/DateRangePicker';
export default {
  props: {
    downloadVisible: {
      type: Boolean,
    },
  },
  components: {  DateRangePicker },
  data() {
    return {
      errorText: `${this.$t('ibReport.defaultTimeMessage')}`,
      startDate:'',
      endDate:''
    }
  },
  methods: {
    close() {
      this.$emit('closeDownloadDoalog')
    },
    handleConfrim(){
      // 间隔天数
      let days = this.$options.filters.formatDateDiff(this.startDate,  this.endDate);
      // 允许下载最大天数
      const MAX_DAYS = 90;
      if (days > MAX_DAYS) {
        this.errorText = this.$t('ibReport.timeLimitMessage');
        return ;
      }
      this.errorText = '';
      this.$emit('onConfrim',this.startDate, this.endDate, true);
    }
  },
  computed: {
    buttonDisabled(){
      return (!this.startDate || !this.endDate) ? true : false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__wrapper .el-dialog {
  padding: 40px 20px;
  max-width: 448px!important;
  .el-dialog__header {
    padding: 0 20px 20px;
    .el-dialog__headerbtn{
      display: block;
      .el-dialog__close{
        color: #004CB2;
        font-size: 20px;
      }
    }
  }
  .el-dialog__body{
    padding: 0;
    .calendar {
      .calendar-start-date,
      .calendar-end-date {
        width: calc((100% - (16px * 2)) / 2) !important;
        .el-input {
          width: 188px !important;
        }
      }
    }
  }
  .el-button{
    text-transform: none;
  }
} 
.ibReportDialog {
  text-align: left;
  .dialog-content {
    overflow: hidden;
  }
  .dialog-footer {
    padding: 0 20px;
  }
  .dialog-err {
    color: #dc3428;
    margin: 0 20px 80px;
  }
}
</style>
<style lang="scss">

</style>
