/***
 *    前端生成excel 表格(基于 js-export-excel 插件的二次封装)
 *    fileName:excel文件名
 *    datas:{
 *      sheetData: 第一行的实例数据
 *      sheetFilter:  和  sheetData 一一对应
 *      sheetHeader:表头
 *      option.datas:  一个对象表示一个表格
 *      columnWidths: 列宽
 *    }
 * ***/
const downLoadExcelMode = async (fileName, datas) => {
  var option = {}
  option.fileName = fileName
  option.datas = datas
  const ExportJsonExcel = require('js-export-excel')
  try {
    var toExcel = new ExportJsonExcel(option)
    await toExcel.saveExcel();
    return true;
  } catch (error) {
    console.log(error)
    return false;
  }
 
}
export { downLoadExcelMode }
  